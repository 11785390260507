import Swiper, { EffectCreative, Navigation, Pagination, Autoplay } from 'swiper';
import { gsap, Power1, Power3 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
import SimpleLightbox from 'simple-lightbox';

export const simpleLightbox = () => {
  new SimpleLightbox({
    elements: document.querySelectorAll('.defGallery a'),
    nextBtnClass: ' icon-arrow-right',
    prevBtnClass: ' icon-arrow-right',
  });
};

export const stickyHeader = () => {
  const siteHeader = document.getElementById('masthead');

  if (!siteHeader) return;

  let header_progress = 0;
  let header_direction = 1;

  ScrollTrigger.create({
    trigger: '#wrapper',
    start: 'top top',
    end: 'bottom bottom',
    onUpdate: function (self) {
      header_progress = self.progress.toFixed(2);
      header_direction = self.direction;
      //console.info('header_progress', header_progress);

      let newClassName;

      if (header_progress === '0.00') {
        newClassName = 'siteHeader siteHeader--top';
      } else if (header_progress === '1.00') {
        newClassName = 'siteHeader siteHeader--notTop siteHeader--bottom';
      } else {
        newClassName = 'siteHeader siteHeader--notTop';

        if (header_direction === 1) {
          // && header_progress > '0.03'
          newClassName += ' siteHeader--unpinned';
        }
      }
      siteHeader.className = newClassName;
    },
  });
};

export const swiperDefault = (selector) => {
  const swiperDefault = document.querySelectorAll(selector);

  swiperDefault.forEach((swiperEl, index) => {
    const section = swiperEl.closest('.section');

    const swOptions = {};

    const sw = new Swiper(swiperEl, {
      modules: [Navigation, Pagination, Autoplay],
      watchOverflow: true,
      autoHeight: swiperEl.dataset.autoHeight ?? false,
      pagination: {
        el: section.querySelector('.swiper-pagination'),
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: section.querySelector('.swiper-button-next'),
        prevEl: section.querySelector('.swiper-button-prev'),
      },
      //autoHeight: true,
      autoplay: {
        pauseOnMouseEnter: true,
        delay: parseInt(swiperEl.dataset.autoplayDelay) ?? 1000000000000,
      },
      grabCursor: true,
      speed: swiperEl.dataset.speed,
      slidesPerView: parseInt(swiperEl.dataset.slidesPerView) ?? 1,
      spaceBetween: parseInt(swiperEl.dataset.spaceBetween) ?? 0,
      on: {
        init: () => {
          //console.info(`${section.id} ${selector} ready`, swiperEl.dataset);
        },
      },
    });
    sw.on('slideChange', function () {
      //console.log('slide changed');
    });
  });
};

/**
 * GSAP functions
 */
export const gsapBatchUpBase = (selector) => {
  ScrollTrigger.saveStyles('.gsapBatchUpBase');

  ScrollTrigger.matchMedia({
    // desktop
    '(min-width: 768px)': function () {
      gsap.utils.toArray('.section').forEach((block) => {
        const items = block.querySelectorAll('.gsapBatchUpBase');
        if (items.length > 0) {
          gsap.set(items, {
            autoAlpha: 0,
            duration: 1000,
            y: 20,
          });

          const stItems = ScrollTrigger.batch(items, {
            interval: 0.3,
            start: () => 'top bottom',
            end: () => 'bottom top',
            onEnter: (batch) =>
              gsap.to(batch, {
                autoAlpha: 1,
                y: 0,
                stagger: 0.2,
                ease: gsap.parseEase('0.79,0.14,0.15,0.86'),
              }),
          });

          const resizeObserver = new ResizeObserver(() => {
            stItems.forEach((stItem) => {
              stItem.refresh();
            });
          });

          // start observing a DOM node
          resizeObserver.observe(document.body);
        }

        /**
         * extra block code
         */
      });

      // when ScrollTrigger does a refresh(), it maps all the positioning data which
      // factors in transforms, but in this example we're initially setting all the ".box"
      // elements to a "y" of 100 solely for the animation in which would throw off the normal
      // positioning, so we use a "refreshInit" listener to reset the y temporarily. When we
      // return a gsap.set() in the listener, it'll automatically revert it after the refresh()!
      /*ScrollTrigger.addEventListener('refreshInit', () => {
        gsap.set(selector, { autoAlpha: 1, y: 0 });
      });*/
    },
    // mobile
    // '(max-width: 767px)': function () {},
    // // all
    // all: function () {
    //   // ScrollTriggers created here aren't associated with a particular media query,
    //   // so they persist.
    // }
  });
};

export const gsapGrid = (
  grid,
  grid_item,
  varsStart,
  varsEnd,
  duration,
  timeout,
  interval
) => {
  const gridItems = document.querySelectorAll(grid_item);

  if (gridItems.length > 0) {
    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 1px)': function () {
        gsap.set(grid_item, varsStart ?? { autoAlpha: 0, y: 50, scale: 0.9 });

        setTimeout(() => {
          gsap.set(grid, { autoAlpha: 1 });

          ScrollTrigger.batch(grid_item, {
            interval: interval ?? 0.2,
            duration: duration ?? 0.64,
            //scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar

            onEnter: (batch) => {
              gsap.to(
                batch,
                varsEnd ?? {
                  autoAlpha: 1,
                  y: 0,
                  scale: 1,
                  stagger: 0.1,
                  ease: gsap.parseEase('0.86,0,0.07,1'),
                }
              );
            },
          });
        }, timeout);
      },
    });
  }
};

export const gsapFooter = (selector) => {
  ScrollTrigger.saveStyles('.footer-scale');

  ScrollTrigger.matchMedia({
    // desktop
    '(min-width: 768px)': function () {
      const footer = document.querySelector('footer');

      const gsapFooter = gsap.timeline({
        scrollTrigger: {
          trigger: document.querySelector('main'),
          start: 'bottom-=130 bottom',
          end: () => 'bottom+=' + footer.offsetHeight + ' bottom',
          scrub: 0.5,
        },
      });

      gsapFooter.fromTo(
        selector,
        {
          y: () => '25%',
          scale: 0.9,
        },
        {
          ease: Power1.easeIn,
          y: () => 0,
          scale: 1,
        }
      );

      let timer = null;

      const resizeObserver = new ResizeObserver(() => {
        if (timer !== null) {
          clearTimeout(timer);
        }

        timer = setTimeout(() => {
          gsapFooter.scrollTrigger.refresh();
        }, 50);
      });

      // start observing a DOM node
      resizeObserver.observe(document.body);
    },
    // mobile
    '(max-width: 767px)': function () {},
    // all
    all: function () {
      // ScrollTriggers created here aren't associated with a particular media query,
      // so they persist.
    },
  });
};
