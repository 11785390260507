'use strict';

import '@/css/app.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {
  stickyHeader,
  swiperDefault,
  simpleLightbox,
  // videoPlayInViewport,
  // setScrollRestoration,
  // logScreenWidth,
  // callBack,
  gsapBatchUpBase,
} from './components/tools';

import CookieThough from 'cookie-though';

import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
// import intersect from '@alpinejs/intersect';
import collapse from '@alpinejs/collapse';
import Autosize from '@marcreichel/alpine-autosize';
// import observer from 'alpinejs'
Alpine.plugin([focus, collapse, Autosize]);

const cookieThough = () => {
  setTimeout(() => {
    CookieThough.init({
      policies: translations.cookieThough.policies,
      essentialLabel: translations.cookieThough.essentialLabel,
      permissionLabels: translations.cookieThough.permissionLabels,
      cookiePreferenceKey: 'cookie-preferences',
      header: translations.cookieThough.header,
      cookiePolicy: translations.cookieThough.cookiePolicy,
      customizeLabel: translations.cookieThough.customizeLabel,
    });
  }, 3000);

  /*document.getElementById("reconsent").addEventListener("click", () => {
    CookieThough.show();
  });*/
};

const main = async () => {
  gsapBatchUpBase('.gsapBatchUpBase');

  stickyHeader();
  swiperDefault('.swiperDefault');
  simpleLightbox();

  cookieThough();

  // Open links in new window
  const links = document.links;

  for (let i = 0; i < links.length; i++) {
    if (
      links[i].hostname !== window.location.hostname ||
      links[i].href.includes('uploads/files')
    ) {
      links[i].rel = 'noopener noreferrer';
      links[i].target = '_blank';
    }
  }

  const blocks = ['bannerHome', 'testimonials', 'realisations', 'team']; //'text', 'textMedia', 'news'
  const components = ['navMenu', 'navMobile'];

  const promises = [];

  for (const block of blocks) {
    const blockElements = document.querySelectorAll(`.${block}`);

    const { init } = await import(`./blocks/${block}.js`);

    blockElements.forEach((block) => {
      promises.push(init(block));
    });
  }

  for (const comp of components) {
    const compElements = document.querySelectorAll(`.${comp}`);

    const { init } = await import(`./components/${comp}.js`);

    compElements.forEach((comp) => {
      promises.push(init(comp));
    });
  }

  await Promise.all(promises).catch((e) => {
    console.error(e.message, e);
  });
};

main().then(() => {
  console.info('Vite <3');
  Alpine.start();
});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR');
  });
}
